import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ children }) => (
  <div className="masthead py-4">
    <div>{children}</div>
  </div>
);

Header.defaultProps = {
  children: null,
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
