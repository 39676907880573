import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import DefaultLayout from '@/layouts/default';
import Masthead from '@/components/layout/masthead';
import SEO from '@/components/layout/seo';
import { FaDownload, FaFilePdf } from 'react-icons/fa';
import {
  AiOutlineDownload,
  AiOutlineFilePdf,
  AiOutlineFileZip,
} from 'react-icons/ai';

const Page = ({ location }) => (
  <DefaultLayout pageInfo={location}>
    <SEO title="About" keywords={[]} />

    <Masthead location={location}>
      <Container className="my-4">
        <Row>
          <Col lg={7}>
            <h2>About</h2>
            <p>
              The <strong>Partnership for Pre-K Improvement (PPI)</strong>{' '}
              Toolkit is designed to help state leaders assess and improve their
              state’s pre-kindergarten (pre-K) policies and implement
              high-quality pre-K that produces equitable opportunities for all
              children. The toolkit was developed with funding from the{' '}
              <a
                rel="noreferrer"
                href="https://usprogram.gatesfoundation.org/Who-We-Are"
                target="_blank"
              >
                Bill & Melinda Gates Foundation
              </a>
              .
            </p>
            <p>
              The experience and expertise of pre-K systems leaders shaped the
              PPI Toolkit. It was developed in partnership with state leaders,
              advocates, and researchers in Oregon, Tennessee, and Washington.
              In addition, more than two dozen local, state, and national early
              learning program leaders, researchers, and policymakers also
              contributed valuable expertise and review of these tools and
              resources. 
            </p>
            <p>
              PPI was an effort led by three organizations: the Alliance for
              Early Success, Cultivate Learning at the University of Washington,
              and Start Early (formerly known as the Ounce of Prevention Fund).
            </p>
          </Col>
        </Row>
        <Row className="my-3">
          <Col lg={9}>
            <div className="d-flex">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    <div className="text-left">
                      Alliance for Early Success creates a powerful community of
                      diverse early childhood policy allies that informs,
                      accelerates, and amplifies their effectiveness in
                      achieving state actions necessary for each and every young
                      child to thrive.
                    </div>
                  </Tooltip>
                }
              >
                <Card className="p-4  align-items-center justify-content-center">
                  <a
                    href="https://earlysuccess.org/"
                    target="_blank"
                    className="no-ext"
                    rel="noreferrer"
                  >
                    <Image
                      src="/images/logo_alliance_for_early_success@2x.png"
                      style={{ maxWidth: '100%' }}
                      className="p-md-3"
                    />
                  </a>
                </Card>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    <div className="text-left">
                      Cultivate Learning, backed by the University of
                      Washington, takes a research-powered, practice-based
                      approach to advancing the continuum of workforce
                      development from early childhood education to youth
                      development. We aim to transform systems and reduce
                      barriers for the workforce by drawing on a cross-section
                      of expertise to develop and implement quality recognition
                      systems, resources, and trainings that spark meaningful
                      connections, activate playful innovation, and center
                      equity and access to quality learning at local, state, and
                      national levels.
                    </div>
                  </Tooltip>
                }
              >
                <Card className="p-4 mx-4 align-items-center justify-content-center">
                  <a
                    href="https://cultivatelearning.uw.edu/"
                    target="_blank"
                    className="no-ext"
                    rel="noreferrer"
                  >
                    <Image
                      src="/images/logo_cultivate_learning@2x.png"
                      style={{ maxWidth: '100%' }}
                      className="p-md-3"
                    />
                  </a>
                </Card>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    <div className="text-left">
                      Start Early is a nonprofit public-private partnership
                      advancing quality early learning and care for families
                      with children, before birth through their earliest years,
                      to help close the opportunity gap. Start Early offers
                      early childhood systems consulting that can support
                      partners to ensure that prenatal to five systems are
                      high-quality and integrated, are resourced to be
                      sustainable, and are designed to serve children and
                      families from historically marginalized communities.
                    </div>
                  </Tooltip>
                }
              >
                <Card className="p-4 align-items-center justify-content-center">
                  <a
                    href="https://startearly.org/resources-professionals/consultation/"
                    target="_blank"
                    className="no-ext"
                    rel="noreferrer"
                  >
                    <Image
                      src="/images/logo_start_early@2x.png"
                      style={{ maxWidth: '100%' }}
                      className="p-md-3"
                    />
                  </a>
                </Card>
              </OverlayTrigger>
            </div>
          </Col>
        </Row>
      </Container>
    </Masthead>

    <Container className="mt-5">
      <Row>
        <Col lg={7}>
          <p>
            Our desired impact with the PPI Toolkit is to provide state early
            learning administrators, researchers, and advocates with the tools
            and resources to support high-quality, equitable early learning
            systems, programs, and practices. This toolkit will support leaders
            to:
          </p>
        </Col>
      </Row>
      <Row className="my-3">
        <Col lg={4}>
          <div className="d-flex mb-3 align-items-center">
            <div className="pr-3">
              <div className="numberCircle background-idm-ice-blue">1</div>
            </div>
            <h5 className="m-0">Engage Stakeholders and Build Partnerships</h5>
          </div>
          <p>
            Help identify critical partners and collaborators to ensure a
            shared, aligned vision for equitable pre-K improvement.
          </p>
        </Col>
        <Col lg={4}>
          <div className="d-flex mb-3 align-items-center">
            <div className="pr-3">
              <div className="numberCircle text-white background-idm-pink">
                2
              </div>
            </div>
            <h5 className="m-0">Assess the System</h5>
          </div>
          <p>
            Assess the quality of the state pre-K system by defining essential
            elements of high-quality pre-K and delineating a measurable
            progression of high-quality policies and practices.
          </p>
        </Col>
        <Col lg={4}>
          <div className="d-flex mb-3 align-items-center">
            <div className="pr-3">
              <div className="numberCircle text-black background-idm-lime">
                3
              </div>
            </div>
            <h5 className="m-0">Implement, Improve, and Advocate</h5>
          </div>{' '}
          <p>
            Engage in continuous quality improvement with a strong focus on
            equity, periodically evaluating the implementation of policies and
            practices to make informed decisions for scaling policies and
            practices that work.
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={7}>
          <h4 className="">Designing the Implementation Development Map</h4>
          <p>
            The central tool of the PPI toolkit is the{' '}
            <strong>Implementation Development Map (IDM)</strong>, a
            process-oriented tool designed to help state leaders implement
            high-quality, equitable pre-kindergarten (pre-K) systems and
            practices that produce positive early learning opportunities for
            every child.{' '}
          </p>
          <p>
            The IDM can be used to describe, assess, prioritize, plan, and
            measure implementation of state pre-K policies and infrastructure
            along seven elements essential to equitable, high-quality pre-K:
            professional development, research-based curriculum, formative child
            assessment, high-quality teaching, data-driven decision making,
            instructional leadership, and political leadership. Each of the
            seven IDM elements consists of three parts:
          </p>
          <ol>
            <li>
              <strong>Key indicators</strong> of recommended research-based
              practices;
            </li>
            <li>
              <strong>Delineation</strong> of a developmental progression of
              these practices; and
            </li>
            <li>
              <strong>Assessment,</strong> through implementation data, of how
              each indicator lands in practice.
            </li>
          </ol>
          <h5>Intended User Benefits:</h5>
          <ul>
            <li>Remaining focused on an equity agenda</li>
            <li>
              Sharing of resources and examples among states that can lead to
              improvement
            </li>
            <li>Identifying data blind spots and improving data systems</li>
            <li>
              Identifying what’s working, for whom, and under what conditions
            </li>
            <li>Identifying innovative practices for potential replication</li>
            <li>Examining biases that prevent equitable systems</li>
          </ul>
          <p>
            The Implementation Development Map (IDM) was developed using a
            design-based implementation process. Iterative cycles involved
            stakeholders, including early learning state leaders, early learning
            experts, researchers, advocates, PPI partners (Start Early and
            Alliance for Early Success), and equity experts providing extensive
            reviews and feedback to create a tool for the pre-K field.{' '}
            <a
              href="/downloads/IDM-acknowledgements.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Please see a full list of acknowledgements
            </a>{' '}
            to all of those who generously gave their time and expertise to this
            effort. The goal was to model a process in which the tool can
            continue to be improved as states implement equitable policies and
            practices in the field using the IDM and provide ongoing evidence to
            illuminate what works, for whom, and under what circumstances.
          </p>
          <div className="position-relative">
            <h5 className="my-1">
              <a
                href="/downloads/IDM-At-A-Glance.pdf"
                className="stretched-link text-black"
              >
                IDM At-a-Glance
                <AiOutlineDownload className="ml-1 dl-icon text-idm-pink" />
              </a>
            </h5>
            <div className="mb-4 fileinfo d-flex align-items-center">
              <AiOutlineFilePdf size="20px" />
              <span className="d-inline-block pt-1 ml-1">PDF, 740 KB</span>
            </div>
          </div>
          <h2>The Research Behind the IDM</h2>
          <p>
            The seven essential Elements included in the IDM are based on the
            research of Jim Minervino’s (2014){' '}
            <em>
              Lessons from Research and the Classroom: Implementing High-Quality
              Pre-K that Makes a Difference for Young Children
            </em>{' '}
            and input from PPI state teams about the most direct path for state
            administrators to impact positive system reform. The elements are
            undergirded by extensive reviews of the peer-reviewed literature and
            policy documents such as early learning standards, the Head Start
            Program Performance Standards, and the National Association for the
            Education of Young Children (NAEYC) Program Standards.
          </p>
          <p>
            Still, we recognize that the elements included in the IDM are not
            exhaustive; for example, family engagement and comprehensive
            services are also essential to robust pre-K systems. We also
            recognize that early learning professionals should be ensured
            equitable compensation for similar skills and training, and that
            pre-K is available to all eligible children.{' '}
          </p>
          <p>
            Please use the links below to view the literature review summaries
            and methodology in more detail.
          </p>

          <div className="position-relative">
            <h5 className="my-1">
              <a
                href="/downloads/IDM-evidence-and-reference-process.pdf"
                className="stretched-link text-black"
              >
                IDM Learning Methodology
                <AiOutlineDownload className="ml-1 dl-icon text-idm-pink" />
              </a>
            </h5>
            <div className="mb-4 fileinfo d-flex align-items-center">
              <AiOutlineFilePdf size="20px" />
              <span className="d-inline-block pt-1 ml-1">PDF, 301 KB</span>
            </div>
          </div>

          <div className="position-relative">
            <h5 className="my-1">
              <a
                href="/downloads/IDM-literature-summaries.zip"
                className="stretched-link text-black"
              >
                IDM Literature Summaries
                <AiOutlineDownload className="ml-1 dl-icon text-idm-pink" />
              </a>
            </h5>
            <div className="mb-4 fileinfo d-flex align-items-center">
              <AiOutlineFileZip size="20px" />
              <span className="d-inline-block pt-1 ml-1">ZIP, 15.7 MB</span>
            </div>
          </div>

          <div className="position-relative">
            <h5 className="my-1">
              <a
                href="/downloads/MPR-literature-scans.zip"
                className="stretched-link text-black"
              >
                Mathematica Literature Scans
                <AiOutlineDownload className="ml-1 dl-icon text-idm-pink" />
              </a>
            </h5>
            <div className="mb-4 fileinfo d-flex align-items-center">
              <AiOutlineFileZip size="20px" />
              <span className="d-inline-block pt-1 ml-1">ZIP, 6 MB</span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    <div className="background-idm-light-gray mt-5">
      <Container className="py-4 py-lg-5">
        <Row className="py-3">
          <Col lg={5}>
            <h2 className="mt-0 mb-3 mb-lg-0">Contact Us</h2>
          </Col>
          <Col lg={5}>
            <p className="my-0">
              For questions about the Partnership for Pre-K Improvement Toolkit,
              please contact{' '}
              <a href="mailto:ppitoolkitinfo@uw.edu">ppitoolkitinfo@uw.edu</a>.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  </DefaultLayout>
);

Page.propTypes = {
  location: PropTypes.shape(),
};

Page.defaultProps = {
  location: null,
};

export default Page;
